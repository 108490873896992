@import '../../../../styles/basics';

.bb-focus-area {
  &:global(.bb) {
    @include bb-standalone-spacing();
  }

  :global {
    .wrapper {
      background: $turquoise-1000;
      display: flex;

      .image {
        width: 250px;
        min-width: 250px;
        min-height: 250px;
        background-size: cover;
      }

      .content {
        padding: 40px 24px;
        flex-grow: 1;
        @include media-breakpoint-up(md) {
          padding: 24px;
        }

        .label {
          color: $white;
          margin-bottom: 6px;
          display: block;
        }

        .title {
          color: $white;
        }

        .description {
          color: $white;

          p {
            color: $white;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        a[data-component^="button-"] {
          margin-top: 24px;
          margin-bottom: 0;
        }
      }
    }
  }
}
